/* MyPage-specific styles */

/* Scope all styles within .date-picker-page */
.date-picker-page {

    /* Hide the calendar icon button inside DatePicker */
    .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
        display: none !important;
    }

    /* Custom styling for the Input Adornment (optional) */
    .css-1laqsz7-MuiInputAdornment-root {
        color: #9e9e9e;
    }

    /* Set height and width for the Outlined Notched Outline */
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color: #0F3D79 !important;
    }

    /* Remove focus outline or style it */
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline:focus {
        outline: none !important;
        border-color: #346BB7;
    }

    /* Standard height and width for the input field */
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        width: 150px !important;
        height: 40px !important;
    }

    /* Custom styling for the Calendar Icon inside IconButton */
    .MuiIconButton-root .MuiSvgIcon-root {
        color: #0F3D79;
        margin: 6px;
        height: 30px;
        width: 30px;
    }
}


/* Custom Menu styling */
.custom-menu {
    width: 100px;
    /* Set a consistent width */
    padding: 8px 0;
    /* Add top and bottom padding */
}

/* MenuItem styling for vertical alignment */
.custom-menu-item {
    font-size: 16px;
    /* Set font size */
    padding: 10px 20px;
  
    color: #0F3D79;

    transition: background-color 0.2s ease;

}

.custom-menu-item:hover {
    background-color: #e0f0ff;
   
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    font-size:10px;
}