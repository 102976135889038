.card:hover {
    transition: transform 1s ease-out;
    transform:scale(1.02) ;
    box-shadow: 4px 4px 4px 4px rgb(208,208,208) ;
}

.joblist:hover{
    background-color: rgb(244,244,244);
}

.box {
    inline-size: 150px;
    overflow-wrap: break-word;
}
  